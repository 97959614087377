html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-highlight: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: 0;
  touch-action: pan-y pan-x;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.map-pin {
  transform: translate(-50%, -100%);
  pointer-events: none;
}

.map-pin._wh {
  width: 20px;
  height: 27px;
}

.map-pin._courier {
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-image: url("../img/icon_vehicle.svg");
  background-color: #24594b;
  background-size: auto 18px;
  background-repeat: no-repeat;
  background-position: 0 center;
}

.map-pin._courier._car {
  background-position: -18px center;
}

.map-pin._courier._footer {
  background-position: -36px center;
}

.map-pin._courier._motorbike {
  background-position: -54px center;
}

.map-pin._order {
  width: 20px;
  height: 20px;
}
